
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91page_93oA1DBXSWVXMeta } from "/app/pages/appeal/[page].vue?macro=true";
import { default as _91page_93Tp3GzoWa7xMeta } from "/app/pages/applications/[page].vue?macro=true";
import { default as _91page_93o3bEnjvAL4Meta } from "/app/pages/blog/[page].vue?macro=true";
import { default as _91name_93f03sTDR0c5Meta } from "/app/pages/blog/detailed/[category]/[name].vue?macro=true";
import { default as indexpEqeHBee6QMeta } from "/app/pages/documents/index.vue?macro=true";
import { default as _91id_93rN4wHvYl8eMeta } from "/app/pages/faq/detailed/[id].vue?macro=true";
import { default as indexkGMs4DN35HMeta } from "/app/pages/faq/index.vue?macro=true";
import { default as _91page_93cqF4wAQpyMMeta } from "/app/pages/feedback/[page].vue?macro=true";
import { default as _91page_93iK0SYx8F6pMeta } from "/app/pages/filesUpload/[page].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91page_93KP2Gt0Af1lMeta } from "/app/pages/news/[page].vue?macro=true";
import { default as _91name_93y3PjWr4B1OMeta } from "/app/pages/news/detailed/[name].vue?macro=true";
import { default as _91page_93DaykYqGwyZMeta } from "/app/pages/pages/[page].vue?macro=true";
import { default as _91name_93KrtYopBqCcMeta } from "/app/pages/pages/detailed/[name].vue?macro=true";
import { default as _91id_93XvPRuS5KCSMeta } from "/app/pages/partners/[id].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as _91id_93PpYZzPUtZVMeta } from "/app/pages/payment/detailed/[id].vue?macro=true";
import { default as indexZu37Q4vm8IMeta } from "/app/pages/payment/index.vue?macro=true";
import { default as _91id_93Bxwbfim3N0Meta } from "/app/pages/paymentMethods/[id].vue?macro=true";
import { default as indexNbSsiv6IJeMeta } from "/app/pages/paymentMethods/index.vue?macro=true";
import { default as _91id_93ul4y6nCraTMeta } from "/app/pages/reviews/[id].vue?macro=true";
import { default as indexcznVuPkAgWMeta } from "/app/pages/reviews/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as _91id_93nDQ2NKWll2Meta } from "/app/pages/users/[id].vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
import { default as _91name_93qCfwfB3K0AMeta } from "/app/pages/vacancies/[city]/[name].vue?macro=true";
import { default as _91page_932j8n4oEwqxMeta } from "/app/pages/vacancies/[page].vue?macro=true";
export default [
  {
    name: "appeal-page",
    path: "/appeal/:page()",
    component: () => import("/app/pages/appeal/[page].vue")
  },
  {
    name: "applications-page",
    path: "/applications/:page()",
    component: () => import("/app/pages/applications/[page].vue")
  },
  {
    name: "blog-page",
    path: "/blog/:page()",
    component: () => import("/app/pages/blog/[page].vue")
  },
  {
    name: "blog-detailed-category-name",
    path: "/blog/detailed/:category()/:name()",
    component: () => import("/app/pages/blog/detailed/[category]/[name].vue")
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/app/pages/documents/index.vue")
  },
  {
    name: "faq-detailed-id",
    path: "/faq/detailed/:id()",
    component: () => import("/app/pages/faq/detailed/[id].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq/index.vue")
  },
  {
    name: "feedback-page",
    path: "/feedback/:page()",
    component: () => import("/app/pages/feedback/[page].vue")
  },
  {
    name: "filesUpload-page",
    path: "/filesUpload/:page()",
    component: () => import("/app/pages/filesUpload/[page].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "news-page",
    path: "/news/:page()",
    component: () => import("/app/pages/news/[page].vue")
  },
  {
    name: "news-detailed-name",
    path: "/news/detailed/:name()",
    component: () => import("/app/pages/news/detailed/[name].vue")
  },
  {
    name: "pages-page",
    path: "/pages/:page()",
    component: () => import("/app/pages/pages/[page].vue")
  },
  {
    name: "pages-detailed-name",
    path: "/pages/detailed/:name()",
    component: () => import("/app/pages/pages/detailed/[name].vue")
  },
  {
    name: "partners-id",
    path: "/partners/:id()",
    component: () => import("/app/pages/partners/[id].vue")
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "payment-detailed-id",
    path: "/payment/detailed/:id()",
    component: () => import("/app/pages/payment/detailed/[id].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/app/pages/payment/index.vue")
  },
  {
    name: "paymentMethods-id",
    path: "/paymentMethods/:id()",
    component: () => import("/app/pages/paymentMethods/[id].vue")
  },
  {
    name: "paymentMethods",
    path: "/paymentMethods",
    component: () => import("/app/pages/paymentMethods/index.vue")
  },
  {
    name: "reviews-id",
    path: "/reviews/:id()",
    component: () => import("/app/pages/reviews/[id].vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/app/pages/reviews/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/app/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/app/pages/users/index.vue")
  },
  {
    name: "vacancies-city-name",
    path: "/vacancies/:city()/:name()",
    component: () => import("/app/pages/vacancies/[city]/[name].vue")
  },
  {
    name: "vacancies-page",
    path: "/vacancies/:page()",
    component: () => import("/app/pages/vacancies/[page].vue")
  }
]